import React, { useEffect, useState } from 'react'
import {
  default as SortOptionComponent,
  SortValue,
} from 'components/SortOption/SortOption'
import { SortOption } from 'types/PublicationList'

interface Props {
  className?: string
  selectedSortOption: SortOption
  onChange: (value: SortOption) => void
}

const ListHeader: React.FC<Props> = ({
  className,
  selectedSortOption,
  onChange,
}) => {
  const cn = `list-header flex items-center sm:justify-end pl-10 ${
    className || ''
  }`

  const [titleSortOption, setTitleSortOption] = useState<SortValue>(
    SortValue.none
  )
  const [sourceSortOption, setSourceSortOption] = useState<SortValue>(
    SortValue.none
  )
  const [dateSortOption, setDateSortOption] = useState<SortValue>(
    SortValue.none
  )

  useEffect(() => {
    const getSortValue = (downOption: SortOption, upOption: SortOption) => {
      if (selectedSortOption === downOption) return SortValue.down
      if (selectedSortOption === upOption) return SortValue.up
      return SortValue.none
    }

    if (
      selectedSortOption === SortOption.byTitleAZ ||
      selectedSortOption === SortOption.byTitleZA
    ) {
      setTitleSortOption(
        getSortValue(SortOption.byTitleAZ, SortOption.byTitleZA)
      )
      setSourceSortOption(SortValue.none)
      setDateSortOption(SortValue.none)
    }
    if (
      selectedSortOption === SortOption.bySourceAZ ||
      selectedSortOption === SortOption.bySourceZA
    ) {
      setSourceSortOption(
        getSortValue(SortOption.bySourceAZ, SortOption.bySourceZA)
      )
      setTitleSortOption(SortValue.none)
      setDateSortOption(SortValue.none)
    }
    if (
      selectedSortOption === SortOption.byDateMostRecentFirst ||
      selectedSortOption === SortOption.byDateMostRecentLast
    ) {
      setDateSortOption(
        getSortValue(
          SortOption.byDateMostRecentFirst,
          SortOption.byDateMostRecentLast
        )
      )
      setTitleSortOption(SortValue.none)
      setSourceSortOption(SortValue.none)
    }
  }, [selectedSortOption])

  return (
    <div className={cn}>
      <SortOptionComponent
        className="mr-20 pl-4 flex-grow"
        value={titleSortOption}
        onChange={(value) => {
          if (value === SortValue.down) {
            onChange(SortOption.byTitleAZ)
          }
          if (value === SortValue.up) {
            onChange(SortOption.byTitleZA)
          }
        }}
      >
        Title
      </SortOptionComponent>
      <SortOptionComponent
        className="mr-24 pr-4"
        value={sourceSortOption}
        onChange={(value) => {
          if (value === SortValue.down) {
            onChange(SortOption.bySourceAZ)
          }
          if (value === SortValue.up) {
            onChange(SortOption.bySourceZA)
          }
        }}
      >
        Source
      </SortOptionComponent>
      <SortOptionComponent
        className="mr-20"
        value={dateSortOption}
        onChange={(value) => {
          if (value === SortValue.down) {
            onChange(SortOption.byDateMostRecentFirst)
          }
          if (value === SortValue.up) {
            onChange(SortOption.byDateMostRecentLast)
          }
        }}
      >
        Time
      </SortOptionComponent>
    </div>
  )
}

export default ListHeader
