import React, { ReactNode } from 'react'
import RoundCheckbox from 'components/RoundCheckbox/RoundCheckbox'

interface Props {
  children?: ReactNode
  className?: string
  isChecked?: boolean
  onChange: (value: boolean) => void
  style?: React.CSSProperties
}

const ListSeparator: React.FC<Props> = ({
  children,
  className,
  isChecked,
  onChange,
  style,
}) => {
  const cn = `list-separator flex items-center pt-3 ${className || ''}`
  return (
    <div className={cn} style={style}>
      <RoundCheckbox
        className="mr-4"
        isChecked={isChecked}
        onChange={onChange}
      />
      <span className="font-bold text-sm flex-shrink-0 mr-4">{children}</span>
      <hr className="w-full border-rich-blue-darkest opacity-10" />
    </div>
  )
}

export default ListSeparator
