import create from 'zustand'
import { persist } from 'zustand/middleware'
import { ViewOption, SortOption } from 'types/PublicationList'

type State = {
  viewOption: ViewOption
  setViewOption: (viewOption: ViewOption) => void
  sortOption: SortOption
  setSortOption: (sortOption: SortOption) => void
}
export const useStorePublicationList = create<State>(
  persist(
    (set, get) => ({
      viewOption: ViewOption.asGallery,
      setViewOption: (viewOption) => set({ viewOption }),
      sortOption: SortOption.byDateMostRecentFirst,
      setSortOption: (sortOption) => set({ sortOption }),
    }),
    {
      name: 'knora-publication-list',
    }
  )
)
