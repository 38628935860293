import { createRef, useEffect, useState } from 'react'

export default function useVisibility(): [boolean, React.RefObject<any>] {
  const currentElement = createRef<any>()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (currentElement.current === null) return
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )
    observer.observe(currentElement.current)
    return () => {
      observer.disconnect()
    }
  }, [currentElement])

  return [isIntersecting, currentElement]
}
