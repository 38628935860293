import { Storage } from 'aws-amplify'

import React, { useEffect, useState } from 'react'
import { mergeWith, isArray, uniqBy } from 'lodash'
import LayoutDefault from 'layouts/LayoutDefault/LayoutDefault'
import { useNotifications } from 'components/Notification/NotificationsContext'
import TopBar from 'components/TopBar/TopBar'
import Sidebar from 'components/Sidebar/Sidebar'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import LogoComponent from 'assets/svgs/logo.svg'
import ListIconComponent from 'assets/svgs/list.svg'
import GalleryIconComponent from 'assets/svgs/gallery.svg'
import Container from 'components/Container/Container'
import Switch from 'components/Switch/Switch'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'
import PublicationList from 'components/Database/PublicationList/PublicationList'
import SearchResultsInfo from 'components/Database/SearchResultsInfo/SearchResultsInfo'
import NoPublications from 'components/Database/NoPublications/NoPublications'
import SortPublicationsDropdown from 'components/Database/SortPublicationsDropdown/SortPublicationsDropdown'
import {
  listPublications,
  deletePublications,
  exportPublications,
} from 'services/publication'
import { Publication } from 'types/amplify/sharedTypes/publication/interfaces/publication.interface'
import { useUser } from '../UserContext'
import useVisibility from 'hooks/useVisibility'
import usePrevious from 'hooks/usePrevious'
import { useStoreSearch } from 'store/search'
import { useStoreExtraction } from 'store/extraction'
import { useStoreConfirmation } from 'store/confirmation'
import { useStorePublication } from 'store/publication'
import { useStorePublicationList } from 'store/publicationList'
import { ViewOption } from 'types/PublicationList'

export default function Database() {
  const { getNotificationsRef } = useNotifications()

  const { user } = useUser()

  const isExtracting = useStoreExtraction((state) => state.isExtracting)
  const prevExtracting = usePrevious({ isExtracting })

  const [isLoadingPublications, setIsLoadingPublications] = useState<boolean>(
    true
  )
  const [publications, setPublications] = useState<{
    [groupByKey: string]: Publication[]
  }>({ undefined: [] })
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [shouldLoadPublications, setShouldLoadPublications] = useState<boolean>(
    true
  )
  const [totalPublications, setTotalPublications] = useState<number>(0)

  const searchQuery = useStoreSearch((state) => state.submittedSearchQuery)

  const setIsDeletingPublications = useStorePublication(
    (state) => state.setIsDeletingPublications
  )

  const isExportingPublications = useStorePublication(
    (state) => state.isExportingPublications
  )
  const setIsExportingPublications = useStorePublication(
    (state) => state.setIsExportingPublications
  )
  const isExportingAllPublications = useStorePublication(
    (state) => state.isExportingAllPublications
  )
  const setIsExportingAllPublications = useStorePublication(
    (state) => state.setIsExportingAllPublications
  )

  const [hasPublications, setHasPublications] = useState<boolean>(false)
  useEffect(() => {
    if (searchQuery || !!totalPublications) {
      setHasPublications(true)
    } else {
      setHasPublications(false)
    }
  }, [searchQuery, totalPublications])

  const selectedViewOption = useStorePublicationList(
    (state) => state.viewOption
  )
  const setSelectedViewOption = useStorePublicationList(
    (state) => state.setViewOption
  )

  const selectedSortOption = useStorePublicationList(
    (state) => state.sortOption
  )
  const setSelectedSortOption = useStorePublicationList(
    (state) => state.setSortOption
  )

  useEffect(() => {
    setNextToken(null)
    setShouldLoadPublications(true)
    setIsLoadingPublications(true)
    document.getElementById('main')?.scrollTo(0, 0)
  }, [selectedSortOption, searchQuery])

  const [totalSearchResults, setTotalSearchResults] = useState<
    number | undefined
  >()
  useEffect(() => {
    if (!searchQuery) {
      setTotalSearchResults(undefined)
    }
    setTotalSearchResults(publications[Object.keys(publications)[0]]?.length)
  }, [searchQuery, publications])

  useEffect(() => {
    const hasExtracted = prevExtracting?.isExtracting && !isExtracting
    if (hasExtracted) {
      setNextToken(null)
      setShouldLoadPublications(true)
      document.getElementById('main')?.scrollTo(0, 0)
    }
  }, [prevExtracting, isExtracting])

  useEffect(() => {
    void (async function () {
      if (!user) return
      if (!shouldLoadPublications) return

      setShouldLoadPublications(false)
      try {
        const [pubs, next] = await listPublications({
          sort: selectedSortOption,
          search: searchQuery,
          owner: user.username,
          nextToken,
        })

        let hasPubs = false
        for (const key of Object.keys(pubs)) {
          if (pubs[key].length) {
            hasPubs = true
            break
          }
        }

        const mergedPubs = nextToken
          ? mergeWith(publications, pubs, (objValue, srcValue) => {
              if (isArray(objValue)) {
                return uniqBy(objValue.concat(srcValue), (pub) => pub.id)
              }
            })
          : pubs
        setPublications(mergedPubs)

        let totalPubs = 0
        for (const key of Object.keys(mergedPubs)) {
          totalPubs += mergedPubs[key].length
        }
        setTotalPublications(totalPubs)
        setNextToken(next)
        if (!hasPubs && next) {
          setShouldLoadPublications(true)
        } else {
          setIsLoadingPublications(false)
        }
      } catch (err) {
        const errorMessage = 'Failed loading publications.'
        console.error(errorMessage, err)
        getNotificationsRef().addNotification({
          text: errorMessage,
          isError: true,
        })
      }
    })()
  }, [
    getNotificationsRef,
    nextToken,
    publications,
    searchQuery,
    selectedSortOption,
    shouldLoadPublications,
    user,
  ])

  const [isLoadMoreVisible, loadMoreElement] = useVisibility()
  useEffect(() => {
    if (!isLoadMoreVisible) return
    setShouldLoadPublications(true)
  }, [isLoadMoreVisible])

  const [disabledPublicationIds, setDisabledPublicationIds] = useState<{
    [publicationId: string]: boolean
  }>({})

  const removePublications = (publicationIds: string[]) => {
    const pubGroupKeys = Object.keys(publications)
    const updatedPubs = { ...publications }
    let totalDeleted = 0
    for (const groupKey of pubGroupKeys) {
      if (totalDeleted === publicationIds.length) break
      const beforeLength = updatedPubs[groupKey].length
      updatedPubs[groupKey] = updatedPubs[groupKey].filter(
        (pub) => !publicationIds.includes(pub.id)
      )
      totalDeleted += beforeLength - updatedPubs[groupKey].length
      if (!updatedPubs[groupKey].length) {
        delete updatedPubs[groupKey]
      }
    }
    setPublications(updatedPubs)

    const allPublications: Publication[] = []
    for (const groupKey of Object.keys(updatedPubs)) {
      const group = updatedPubs[groupKey]
      allPublications.push(...group)
    }
    if (!allPublications.length) {
      setNextToken(null)
      setShouldLoadPublications(true)
    }
  }

  const disablePublications = (publicationIds: string[]) => {
    const map = { ...disabledPublicationIds }
    for (const id of publicationIds) {
      map[id] = true
    }
    setDisabledPublicationIds(map)
  }
  const enablePublications = (publicationIds: string[]) => {
    const map = { ...disabledPublicationIds }
    for (const id of publicationIds) {
      delete map[id]
    }
    setDisabledPublicationIds(map)
  }

  const onDeletePublicationsConfirm = async (publicationIds: string[]) => {
    // disable publication tile and show loader
    disablePublications(publicationIds)

    const failedPublicationIds: string[] = []
    const succeededPublicationIds: string[] = []
    try {
      const result = await deletePublications(publicationIds)
      failedPublicationIds.push(...result.failedPublicationIds)
      succeededPublicationIds.push(...result.succeededPublicationIds)
    } catch (err) {
      console.error('Failed deleting publications.', err)
      failedPublicationIds.push(...publicationIds)
    }

    if (failedPublicationIds.length) {
      let errorMessage
      if (publicationIds.length === 1 || failedPublicationIds.length === 1) {
        errorMessage = `Failed deleting publication with id: ${failedPublicationIds[0]}`
      } else {
        errorMessage = `Failed deleting ${failedPublicationIds.length} of ${publicationIds.length} publications.`
      }
      getNotificationsRef().addNotification({
        text: errorMessage,
        isError: true,
      })
    } else {
      let successMessage
      if (succeededPublicationIds.length === 1) {
        successMessage = `Publication has been deleted.`
      } else {
        successMessage = `${succeededPublicationIds.length} publications have been deleted.`
      }
      getNotificationsRef().addNotification({
        text: successMessage,
        color:
          window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--vibrant-green-dark') || undefined,
      })
    }

    enablePublications(failedPublicationIds)
    removePublications(succeededPublicationIds)
    setIsDeletingPublications(false)
  }

  const onDeletePublications = async (publicationIds: string[]) => {
    useStoreConfirmation.setState({
      isConfirmationModalOpen: true,
      headline: `Are you sure you want to delete ${
        publicationIds.length === 1
          ? 'this publication'
          : 'all selected publications'
      }?`,
      onConfirm: () => {
        onDeletePublicationsConfirm(publicationIds)
      },
      onCancel: () => {
        setIsDeletingPublications(false)
      },
    })
  }

  const onExportPublications = async (publicationIds: string[]) => {
    const isExportingAll = !publicationIds.length
    let failedExportingAll = false
    const failedPublicationIds: string[] = []
    let key = ''
    try {
      const result = await exportPublications({ publicationIds })
      failedPublicationIds.push(...result.failedPublicationIds)
      key = result.key
    } catch (err) {
      console.error('Failed exporting publications.', err)
      if (isExportingAll) {
        failedExportingAll = true
      } else {
        failedPublicationIds.push(...publicationIds)
      }
    }

    if (failedPublicationIds.length || failedExportingAll) {
      let errorMessage
      if (publicationIds.length === 1 || failedPublicationIds.length === 1) {
        errorMessage = `Failed exporting publication with id: ${failedPublicationIds[0]}`
      } else {
        errorMessage = `Failed exporting ${failedPublicationIds.length}${
          publicationIds.length ? ' of ' + publicationIds.length : ''
        } publications.`
      }
      getNotificationsRef().addNotification({
        text: errorMessage,
        isError: !key,
        isInfo: !!key,
      })
    } else {
      const successMessage = `Publication${
        publicationIds.length === 1 ? ' has' : 's have'
      } been exported.`
      getNotificationsRef().addNotification({
        text: successMessage,
        color:
          window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--vibrant-green-dark') || undefined,
      })
    }

    if (key) {
      const link = await Storage.get(key.replace('dl/', ''), {
        level: 'public',
        cacheControl: 'no-cache',
        download: false,
        contentType: 'application/pdf',
        contentDisposition: `attachment; filename="exported_publications.csv"`,
        customPrefix: {
          public: 'dl/',
        },
      })

      const win = window.open(link as string, '_blank', 'noreferrer')
      win?.focus()
    }

    if (isExportingAll) {
      setIsExportingAllPublications(false)
    } else {
      setIsExportingPublications(false)
    }
  }

  const GalleryIcon = GalleryIconComponent as any
  const ListIcon = ListIconComponent as any
  const Logo = LogoComponent as any

  return (
    <>
      <LayoutDefault topBar={<TopBar />} sidebar={<Sidebar />}>
        {isLoadingPublications ? (
          <Container className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center text-center w-full">
              <Logo className="mb-4" />
              <div className="py-2 px-6">
                <LoadingIndicator className="text-rich-blue font-bold">
                  Loading publications
                </LoadingIndicator>
              </div>
            </div>
          </Container>
        ) : (
          <>
            {!hasPublications ? (
              <Container className="flex flex-col items-center justify-center">
                <NoPublications />
              </Container>
            ) : (
              <Container style={{ paddingTop: 0 }}>
                <div
                  data-test="publication-list-toolbar"
                  className="flex items-start justify-end pt-6 bg-sensitive-grey sticky top-0 z-20"
                  style={{ height: '8rem' }}
                >
                  <div className="-mr-4 sm:mr-10 text-sensitive-green order-2 sm:order-1">
                    {!searchQuery && totalPublications ? (
                      <SortPublicationsDropdown
                        data-test="select-sort-publications"
                        selectedSortOption={selectedSortOption}
                        setSelectedSortOption={setSelectedSortOption}
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  {totalPublications ? (
                    <button
                      data-test="btn-export-all-publications"
                      disabled={
                        isExportingPublications || isExportingAllPublications
                      }
                      className={`order-1 mb-3 sm:mb-1 sm:order-2 mr-auto sm:mr-0 flex h-10 items-center bg-rich-blue hover:bg-rich-blue-dark py-2 focus:bg-rich-blue-dark active:bg-rich-blue-darker px-6 rounded-md font-black text-white ${
                        isExportingPublications || isExportingAllPublications
                          ? 'opacity-50 cursor-default'
                          : ''
                      }`}
                      onClick={() => {
                        setIsExportingAllPublications(true)
                        onExportPublications([])
                      }}
                    >
                      {isExportingAllPublications ? (
                        <>
                          <RoundLoadingIndicator className="mr-3" />
                          <span>Exporting</span>
                        </>
                      ) : (
                        'Export all as .csv'
                      )}
                    </button>
                  ) : (
                    ''
                  )}

                  {totalPublications ? (
                    <div className="absolute bottom-0 h-0">
                      <div className="inline-block pl-4 pb-2 rounded-full bg-sensitive-grey">
                        <Switch
                          data-test="switch-view-option"
                          iconOn={<ListIcon />}
                          iconOff={<GalleryIcon />}
                          isOn={selectedViewOption === ViewOption.asList}
                          onChange={(isOn) => {
                            setSelectedViewOption(
                              isOn ? ViewOption.asList : ViewOption.asGallery
                            )
                          }}
                        >
                          Switch between list and gallery view
                        </Switch>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                {searchQuery && (
                  <SearchResultsInfo
                    searchQuery={searchQuery}
                    totalSearchResults={totalSearchResults}
                  />
                )}

                <div>
                  <PublicationList
                    publications={publications}
                    disabledPublicationIds={disabledPublicationIds}
                    selectedSortOption={selectedSortOption}
                    searchQuery={searchQuery}
                    totalSearchResults={totalSearchResults}
                    selectedViewOption={selectedViewOption}
                    onDeleteClick={onDeletePublications}
                    onExportClick={onExportPublications}
                  />

                  {nextToken && (
                    <LoadingIndicator
                      data-test="publication-list-load-more"
                      ref={loadMoreElement}
                      className="block text-rich-blue font-bold text-center"
                    >
                      Loading more publications
                    </LoadingIndicator>
                  )}
                </div>
              </Container>
            )}
          </>
        )}
      </LayoutDefault>
    </>
  )
}
