import React from 'react'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  searchQuery?: string
  totalSearchResults?: number
}

const SearchResultsInfo = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const cn = `absolute w-full z-20 ${props.className || ''}`
    return (
      <div ref={ref} className={cn}>
        <p className="transform -translate-y-full absolute text-rich-blue-darkest opacity-75">
          Your search for <span className="font-bold">{props.searchQuery}</span>
          {props.totalSearchResults ? ':' : <span> returned no results.</span>}
        </p>
      </div>
    )
  }
)

export default SearchResultsInfo
