import React, { ReactNode, useState } from 'react'

import './Switch.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  children: ReactNode
  iconOn?: ReactNode
  iconOff?: ReactNode
  isOn?: boolean
  name?: string
  id?: string
  disabled?: boolean
  onChange: (isOn: boolean) => void
  theme?: 'vibrant-cyan' | 'rich-blue' | 'rich-purple' | 'vibrant-magenta'
  'data-test'?: string
}

const Switch: React.FC<Props> = ({
  className,
  style,
  children,
  isOn,
  iconOn,
  iconOff,
  name,
  id,
  disabled,
  onChange,
  theme = 'rich-blue',
  'data-test': dataTest,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(isOn || false)

  const cn = `switch relative bg-white rounded-full flex justify-around items-center border-0 outline-none p-0 h-10 w-20 cursor-pointer ${
    isSwitchOn ? 'switch--on' : 'switch--off'
  } ${className || ''}`

  let switchSwitchCn =
    'switch__switch absolute h-full w-1/2 top-0 left-0 rounded-full'
  const switchBGColors = [
    'bg-vibrant-cyan',
    'bg-rich-purple',
    'bg-vibrant-magenta',
    'bg-rich-blue',
  ]
  switchSwitchCn += ` ${switchBGColors.find((bgc) => bgc.includes(theme))}`

  const switchIconCn =
    'switch__icon fill-current stroke-current absolute w-1/2 flex justify-center'
  const switchTextColors = [
    'text-vibrant-cyan',
    'text-rich-purple',
    'text-vibrant-magenta',
    'text-rich-blue',
  ]
  const switchIconThemeCn = switchTextColors.find((bgc) => bgc.includes(theme))

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    const value = !isSwitchOn
    setIsSwitchOn(value)
    onChange(value)
  }

  return (
    <button
      data-test={dataTest}
      id={id}
      name={name}
      className={cn}
      style={style}
      role="switch"
      aria-checked={isSwitchOn ? 'true' : 'false'}
      onClick={handleClick}
      disabled={disabled}
    >
      <span
        className={`switch__icon--on left-0 ${switchIconThemeCn} ${switchIconCn}`}
      >
        {iconOn}
      </span>
      <span
        className={`switch__icon--off right-0 ${switchIconThemeCn} ${switchIconCn}`}
      >
        {iconOff}
      </span>
      <span className={switchSwitchCn} />
      <span className="sr-only">{children}</span>
      <span
        style={{ opacity: isSwitchOn ? 1 : 0 }}
        className={`switch__icon--on left-0 text-white ${switchIconCn}`}
      >
        {iconOn}
      </span>
      <span
        style={{ opacity: isSwitchOn ? 0 : 1 }}
        className={`switch__icon--off right-0 text-white ${switchIconCn}`}
      >
        {iconOff}
      </span>
    </button>
  )
}

export default Switch
