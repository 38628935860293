import React, { useEffect, useState } from 'react'
import { SortOption } from 'types/PublicationList'
import { PublicationWithoutRelations } from 'types/amplify/sharedTypes/publication/interfaces/publication.interface'
import { PublicationSource } from 'types/amplify/sharedTypes/publication/enums/publicationSource'
import ListSeparator from './ListSeparator/ListSeparator'
import ListHeader from './ListHeader/ListHeader'
import ListItem from './ListItem/ListItem'
import DeleteExportToolbar from 'components/DeleteExportToolbar/DeleteExportToolbar'
import AdaptiveDate from 'components/AdaptiveDate/AdaptiveDate'
import Toaster from 'components/Toaster/Toaster'
import { useStorePublication } from 'store/publication'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  publications: {
    [groupByKey: string]: PublicationWithoutRelations[]
  }
  disabledPublicationIds: {
    [publicationId: string]: boolean
  }
  onDeleteClick: (publicationId: string[]) => void
  onExportClick: (publicationId: string[]) => void
  searchQuery?: string
  totalSearchResults?: number
  selectedSortOption: SortOption
  selectedViewOption: ViewOption
}

export enum ViewOption {
  asList = 'asList',
  asGallery = 'asGallery',
}

const PublicationList = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const cn = `${props.className || ''}`

    const [selectedPublications, setSelectedPublications] = useState<
      PublicationWithoutRelations[]
    >([])
    useEffect(() => {
      const allPublications: PublicationWithoutRelations[] = []
      for (const groupKey of Object.keys(props.publications)) {
        const group = props.publications[groupKey]
        allPublications.push(...group)
      }

      const updatedSelectedPublications = [
        ...selectedPublications.filter(
          (selectedPublication) =>
            !!allPublications.find(
              (publication) => publication.id === selectedPublication.id
            )
        ),
      ]
      if (updatedSelectedPublications.length === selectedPublications.length) {
        return
      }
      setSelectedPublications([
        ...selectedPublications.filter(
          (selectedPublication) =>
            !!allPublications.find(
              (publication) => publication.id === selectedPublication.id
            )
        ),
      ])
    }, [props.publications, selectedPublications])

    const [internalSorting, setInternalSorting] = useState<
      { groupKey: string; sortOption: SortOption }[]
    >([])

    const getInternallySelectedSortOption = (groupKey: string) => {
      const internallySelectedSortOption = internalSorting.find(
        (s) => s.groupKey === groupKey
      )
      if (!internallySelectedSortOption) {
        return props.selectedSortOption
      }
      return internallySelectedSortOption.sortOption
    }

    const setInternallySelectedSortOption = (
      groupKey: string,
      sortOption: SortOption
    ) => {
      const without = internalSorting.filter((s) => s.groupKey !== groupKey)
      if (sortOption === props.selectedSortOption) {
        setInternalSorting([...without])
        return
      }
      setInternalSorting([...without, { groupKey, sortOption }])
    }

    const isDeletingPublication = useStorePublication(
      (state) => state.isDeletingPublications
    )
    const setIsDeletingPublication = useStorePublication(
      (state) => state.setIsDeletingPublications
    )

    const isExportingPublications = useStorePublication(
      (state) => state.isExportingPublications
    )
    const isExportingAllPublications = useStorePublication(
      (state) => state.isExportingAllPublications
    )
    const setIsExportingPublications = useStorePublication(
      (state) => state.setIsExportingPublications
    )

    return (
      <div data-test="publication-list" ref={ref} className={cn}>
        {Object.keys(props.publications).map((groupKey) => {
          const group = props.publications[groupKey]
          return (
            <div key={groupKey + group.length}>
              <ListSeparator
                className="flex-grow pb-6 bg-sensitive-grey z-10 sticky"
                style={{ top: '8rem' }}
                isChecked={
                  !group.find(
                    (p) => !selectedPublications.find((sp) => sp.id === p.id)
                  )
                }
                onChange={(isSelected) => {
                  const without = selectedPublications.filter(
                    (sp) => !group.find((p) => p.id === sp.id)
                  )
                  setSelectedPublications(
                    isSelected ? [...without, ...group] : without
                  )
                }}
              >
                {props.searchQuery
                  ? `${props.totalSearchResults} ${
                      props.totalSearchResults === 1 ? 'result' : 'results'
                    }`
                  : ''}
                {!props.searchQuery &&
                (props.selectedSortOption ===
                  SortOption.byDateMostRecentFirst ||
                  props.selectedSortOption ===
                    SortOption.byDateMostRecentLast) ? (
                  <AdaptiveDate date={new Date(groupKey)} noTime />
                ) : (
                  ''
                )}
                {!props.searchQuery &&
                (props.selectedSortOption === SortOption.byTitleAZ ||
                  props.selectedSortOption === SortOption.byTitleZA)
                  ? groupKey
                  : ''}
              </ListSeparator>

              {props.selectedViewOption === ViewOption.asList && (
                <ListHeader
                  className="mb-4"
                  selectedSortOption={getInternallySelectedSortOption(groupKey)}
                  onChange={(s) => {
                    setInternallySelectedSortOption(groupKey, s)
                  }}
                />
              )}

              <ul
                className={`${
                  props.selectedViewOption === ViewOption.asList
                    ? 'mb-8'
                    : 'mb-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10'
                }`}
              >
                {[...group]
                  .sort((pubA, pubB) => {
                    const sortOption = getInternallySelectedSortOption(groupKey)
                    if (sortOption === SortOption.bySourceAZ) {
                      return pubA.pubmedIdOrFilename.toLowerCase() >
                        pubB.pubmedIdOrFilename.toLowerCase()
                        ? 1
                        : -1
                    }
                    if (sortOption === SortOption.bySourceZA) {
                      return pubA.pubmedIdOrFilename.toLowerCase() <
                        pubB.pubmedIdOrFilename.toLowerCase()
                        ? 1
                        : -1
                    }
                    if (sortOption === SortOption.byTitleAZ) {
                      return pubA.title.toLowerCase() > pubB.title.toLowerCase()
                        ? 1
                        : -1
                    }
                    if (sortOption === SortOption.byTitleZA) {
                      return pubA.title.toLowerCase() < pubB.title.toLowerCase()
                        ? 1
                        : -1
                    }
                    if (sortOption === SortOption.byDateMostRecentLast) {
                      return (pubA.createdAt || 0) > (pubB.createdAt || 0)
                        ? 1
                        : -1
                    }
                    return (pubA.createdAt || 0) < (pubB.createdAt || 0)
                      ? 1
                      : -1
                  })
                  .map((publication) => (
                    <ListItem
                      key={publication.id}
                      data-test={`publication-list-item-${publication.pubmedIdOrFilename}`}
                      publicationId={publication.id}
                      date={new Date(publication.createdAt || '')}
                      source={
                        publication.source === PublicationSource.Pdf
                          ? publication.pubmedIdOrFilename
                          : `PMID ${publication.pubmedIdOrFilename}`
                      }
                      onSelectionChange={(isSelected) => {
                        const without = selectedPublications.filter(
                          (p) => p.id !== publication.id
                        )
                        setSelectedPublications(
                          isSelected ? [...without, publication] : without
                        )
                      }}
                      onDeleteClick={() =>
                        props.onDeleteClick([publication.id])
                      }
                      isSelected={selectedPublications.includes(publication)}
                      title={publication.title}
                      type={
                        props.selectedViewOption === ViewOption.asList
                          ? 'list-item'
                          : 'tile'
                      }
                      disabled={!!props.disabledPublicationIds[publication.id]}
                      loading={!!props.disabledPublicationIds[publication.id]}
                      totalGenes={publication.genesCount || 0}
                    />
                  ))}
              </ul>
            </div>
          )
        })}

        <Toaster
          className="absolute"
          isToasted={!!selectedPublications.length}
          toastClassName="min-w-2/3 md:min-w-auto"
        >
          <DeleteExportToolbar
            onDelete={() => {
              setIsDeletingPublication(true)
              props.onDeleteClick(
                selectedPublications.map((publication) => publication.id)
              )
            }}
            isDeleting={isDeletingPublication}
            isExporting={isExportingPublications}
            isExportingAll={isExportingAllPublications}
            onExport={() => {
              setIsExportingPublications(true)
              props.onExportClick(
                selectedPublications.map((publication) => publication.id)
              )
            }}
            totalSelected={selectedPublications.length}
            subject="publication"
          />
        </Toaster>
      </div>
    )
  }
)

export default PublicationList
