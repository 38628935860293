import React from 'react'
import { Button, Headline } from '@liquid-design/liquid-design-react'
import { useStoreExtraction } from 'store/extraction'
import ProgressButton from 'components/ProgressButton/ProgressButton'
import PlusIconComponent from 'assets/svgs/plus.svg'
import LogoComponent from 'assets/svgs/logo.svg'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  style?: React.CSSProperties
  id?: string
}

const NoPublications = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const cn = `flex flex-col items-center text-center w-full ${
    props.className || ''
  }`

  const setIsAddPMIDsModalOpen = useStoreExtraction(
    (state) => state.setIsAddPMIDsModalOpen
  )
  const openAddPMIDsModal = () => {
    setIsAddPMIDsModalOpen(true)
  }
  const isExtracting = useStoreExtraction((state) => state.isExtracting)
  const extractionProgress = useStoreExtraction(
    (state) => state.extractionProgress
  )

  const Logo = LogoComponent as any
  const PlusIcon = PlusIconComponent as any

  return (
    <div ref={ref} className={cn}>
      <Logo className="mb-4" />
      <Headline type="H1" className="py-2 px-6">
        Your database is empty.
      </Headline>
      <p className="font-bold text-rich-black-lighter mb-10">
        No genes extracted yet.
      </p>

      {isExtracting ? (
        <ProgressButton
          size="big"
          className="mb-10"
          progress={extractionProgress}
          onClick={openAddPMIDsModal}
        >
          Extraction in progress {Math.round(extractionProgress * 100)}%
        </ProgressButton>
      ) : (
        <Button size="big" className="mb-10" onClick={openAddPMIDsModal}>
          <PlusIcon className="inline-block mr-4" />
          Start your first extraction
        </Button>
      )}
    </div>
  )
})

export default NoPublications
