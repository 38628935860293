import React from 'react'
import { Link } from 'gatsby'
import RoundCheckbox from 'components/RoundCheckbox/RoundCheckbox'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'
import AdaptiveDate from 'components/AdaptiveDate/AdaptiveDate'
import { FlyOut } from '@liquid-design/liquid-design-react'

import './ListItem.css'

interface Props {
  className?: string
  disabled: boolean
  loading: boolean
  style?: React.CSSProperties
  date: Date
  isSelected?: boolean
  onSelectionChange: (value: boolean) => void
  onDeleteClick: () => void
  publicationId: string
  source?: string
  title: string
  totalGenes?: number
  type?: 'tile' | 'list-item'
  'data-test'?: string
}

const ListItem: React.FC<Props> = ({
  className,
  disabled,
  loading,
  style,
  date,
  isSelected,
  onSelectionChange,
  onDeleteClick,
  publicationId,
  source,
  title,
  totalGenes = 0,
  type = 'tile',
  'data-test': dataTest,
}) => {
  let cnAnchor =
    'extraction-list-item flex justify-between bg-white px-4 text-left relative'
  const cnTile =
    'extraction-list-item--tile rounded-md flex-wrap pt-4 pb-6 h-full items-end'
  const cnListItem = 'extraction-list-item--li w-full pt-3'
  cnAnchor += ` ${type === 'tile' ? cnTile : cnListItem}`
  return (
    <li
      data-test={dataTest}
      className={`extraction-list-item text-sm list-none ${
        type === 'tile'
          ? ''
          : 'border-b last:border-b-0 border-sensitive-grey-darkest'
      } ${disabled ? 'opacity-50 pointer-events-none' : ''} ${className || ''}`}
      style={style}
    >
      <div className={cnAnchor}>
        <Link
          className="absolute inset-0"
          to={`/publication/${publicationId}`}
        ></Link>
        {loading ? (
          <RoundLoadingIndicator
            style={{ transform: 'translateX(-0.1rem)' }}
            className="mr-4 self-start"
          />
        ) : (
          <RoundCheckbox
            className="mr-4 self-start"
            isChecked={isSelected}
            onChange={onSelectionChange}
          />
        )}

        <FlyOut
          onClick={(event: React.MouseEvent) => event.preventDefault()}
          className={`extraction-list-item__flyout ${
            type === 'tile' ? 'self-start' : 'order-last'
          }`}
          name={''}
          options={[
            {
              name: 'Delete',
              onClick: onDeleteClick,
            },
          ]}
          label=""
        />

        <div
          className={`list-item__info flex ${
            type === 'tile'
              ? 'list-item__info--tile w-full flex-col'
              : 'list-item__info--li flex-wrap flex-grow mr-4'
          }`}
        >
          <p
            title={title || ''}
            style={{ maxWidth: '50ch' }}
            className={`list-item__title font-bold ${
              type === 'tile'
                ? 'w-full flex-shrink-0 my-2 truncate-4-lines flex-1'
                : 'truncate mb-2 pr-6'
            }`}
          >
            {title}
          </p>

          <div
            className={`${
              type === 'tile'
                ? 'flex-shrink-0 mb-4 h-5 overflow-hidden -mx-4 -mt-3'
                : 'hidden'
            }`}
          >
            <hr className="w-full flex-shrink-0 mb-4 shadow-md opacity-50 border-0 h-full transform translate-y-5 rotate-180" />
          </div>

          <div
            className={`${
              type === 'tile'
                ? 'flex-1'
                : 'flex flex-grow items-baseline flex-wrap max-w-full'
            }`}
          >
            <div
              className={`list-item__date-and-source flex ${
                type === 'tile'
                  ? 'w-full flex-wrap justify-between'
                  : 'order-last mb-3 flex-grow justify-between sm:justify-end max-w-full'
              }`}
            >
              <span
                title={source || ''}
                style={{ maxWidth: '30ch' }}
                className={`list-item__source text-rich-black-lightest truncate ${
                  type === 'tile' ? 'flex-shrink mb-2 mr-6' : 'mr-2'
                }`}
              >
                {source || ''}
              </span>
              <span
                className={`list-item__date text-right flex-shrink-0 ${
                  type === 'tile' ? 'mb-4 truncate' : 'mr-6 w-40'
                }`}
              >
                <AdaptiveDate date={date} />
              </span>
            </div>

            <span
              className={`list-item__total-genes bg-vibrant-cyan text-white font-black text-xs rounded-full px-4 py-1 mr-4 ${
                type === 'tile' ? '' : ''
              }`}
            >
              {/*{Number(totalGenes).toLocaleString('en')}&nbsp;*/}
              {totalGenes > 99 ? '> 99' : totalGenes}{' '}
              {totalGenes === 1 ? 'gene' : 'genes'}
            </span>

            <span
              className={`list-item__show-all text-vibrant-cyan font-black text-xs py-1 ${
                type === 'tile' ? '' : 'mr-6 flex-grow mb-2'
              }`}
            >
              {totalGenes ? <>Show all</> : ''}
            </span>
          </div>
        </div>
      </div>
    </li>
  )
}

export default ListItem
