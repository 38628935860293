import React from 'react'
import { SortOption } from 'types/PublicationList'
import { Dropdown } from '@liquid-design/liquid-design-react'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  selectedSortOption: SortOption
  setSelectedSortOption: (sortOption: SortOption) => void
  'data-test'?: string
}

const SortPublicationsDropdown = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const cn = `${props.className || ''}`
    return (
      <Dropdown
        data-test={props['data-test']}
        className={cn}
        ref={ref}
        label="Sort by"
        options={[
          {
            name: 'Sort by date (most recent first)',
            id: SortOption.byDateMostRecentFirst,
          },
          {
            name: 'Sort by date (most recent last)',
            id: SortOption.byDateMostRecentLast,
          },
          {
            name: 'Sort by title (A to Z)',
            id: SortOption.byTitleAZ,
          },
          {
            name: 'Sort by title (Z to A)',
            id: SortOption.byTitleZA,
          },
          {
            name: 'Sort by source (A to Z)',
            id: SortOption.bySourceAZ,
          },
          {
            name: 'Sort by source (Z to A)',
            id: SortOption.bySourceZA,
          },
        ]}
        value={props.selectedSortOption}
        onSubmit={(option: { id: SortOption }) =>
          props.setSelectedSortOption(option.id)
        }
        inline
      />
    )
  }
)

export default SortPublicationsDropdown
